function leftNav() {
  var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  var d = $(document).scrollTop();

  $(".dropdown-menu").each(function(){
    p = $(this).offset();

    //horizontal
    if ((p.left + $(this).width()) > $(window).innerWidth()) {
      $(this).addClass('menu-left');
    }
  });
}

var doubleTapMenus = function(){
  //double tapping on iPad menus (it assumes Modernizr is being used)
  if(!$('html').hasClass('no-touch')){
    var lastTouch = '';
    $('.nav-primary li.dropdown > a').on('click', function(e){
      if(!$(this).hasClass('touched') || lastTouch != $(this).attr('href')){
        e.preventDefault();
      }
      $(this).addClass('touched');
      lastTouch = $(this).attr('href');
    });
  }
};

$(document).ready(function() {
	doubleTapMenus();

  $('.nav-toggle').on('click', function(){
    if ($('body').hasClass('nav-open')) {
      $('body').removeClass('nav-open');
    } else {
      $('body').addClass('nav-open');
    }
  });

  $('.site-overlay').on('click', function(){
    $('body').removeClass('nav-open');
  });

  $('.nav-arrow').on('click', function(){
    $this = $(this);
    $parent = $this.parent('span');

    $parent.toggleClass('dropdown-menu-open');

    if (!$parent.hasClass('dropdown-menu-open')) {
      $parent.next().stop().slideUp();
    } else {
      $parent.next().stop().slideDown();
    }
  });

  $('.nav-primary a').on('focus', function() {
    $(this).parents('li').addClass('focussed');
  }).on('blur', function() {
    $(this).parents('li').removeClass('focussed');
  });

  leftNav();

  function goToByScroll(id){
    // Remove "link" from the ID
    id = id.replace("link", "");
    // Scroll
    $('html, body').animate({
      scrollTop: $("#"+id).offset().top
    }, 'slow');
  }

  //
  $( "[data-copyto]" ).bind('keypress keyup blur', function() {
    $copyTo = $($(this).attr("data-copyto"));

    if ( $copyTo.length > 0  ) {
      $copyTo.val($(this).val())
    }
  });
});

$('#fromName').bind('keypress keyup blur', function() {
  $('#fullName').val($(this).val());
});

$(window).on('resize', function() {
  $('.dropdown-menu').removeClass('menu-left');
  leftNav();
});

$(window).scroll(function() {
  var $scroll = $(window).scrollTop();
  var $offset = $(".header-primary").height();

  if ($scroll >= $offset) {
    $(".header-primary").addClass("header-small");
  } else {
    $(".header-primary").removeClass("header-small");
  }
});
